<template>
	<Transition>
		<nav class="fixed mx-auto w-full-vw z-10" :class="{
			'blur-xs': !!popup,
			'-translate-y-20': !navbarInView && !hamburgerState,
			}">
			<div class="flex bg-white-300 w-11/12 m-auto mt-5 px-4 py-2 rounded-xl shadow-2xl
						m-lg:flex-col m-lg:px-2.5 m-lg:absolute m-lg:left-1/2 m-lg:transform m-lg:-translate-x-1/2 m-lg:gap-2
						xl:w-4/5 2xl:w-2/3 border border-gray-750 m-lg:max-h-95vh
						transform transition-transform duration-300 ease-in-out"
			     :class="{
						'm-lg:overflow-y-scroll': hamburgerState
					 }"
			     v-click-outside="closeNavDropdowns"
			>

				<!-- Logo and hamburger button -->
				<div class="flex justify-between items-center">
					<NuxtLink no-prefetch to="/" class="flex flex-0 items-center m-lg:justify-between"
					          :style="{'m-lg:mb-1': !hamburgerState}"
					>
						<img alt="Logo of Minecraft IP List" src="/images/full_logo.svg" width="123" height="120">
					</NuxtLink>

					<!-- Hamburger button -->
					<div class="cursor-pointer lg:hidden" @click="toggleHamburger">
						<span>
							<img v-if="!hamburgerState" src="/icons/hamburger.svg" alt="Hamburger" width="24"
							     height="24">
							<svg v-else class="feather feather-x" fill="none" height="24" stroke="currentColor"
							     stroke-linecap="round"
							     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
							     xmlns="http://www.w3.org/2000/svg">
			                    <line x1="18" x2="6" y1="6" y2="18"/>
                                <line x1="6" x2="18" y1="6" y2="18"/>
                            </svg>
                        </span>
					</div>
				</div>

				<!-- Links -->
				<div class="flex flex-1 justify-center gap-3 items-center text-sm xl:ml-24 lg:ml-12 m-lg:flex-col lg:max-w-fit lg:flex-grow"
				     :class="{ 'm-lg:hidden': !hamburgerState }">
					<NavbarLinks :closeNav="closeNavDropdowns"/>
				</div>

				<!-- Social media links -->
				<div class="flex items-center gap-3 m-lg:justify-center lg:mx-auto"
				     :class="{ 'm-lg:hidden': !hamburgerState }">
					<NuxtLink no-prefetch to="https://twitter.com/minecraftiplist" target="_blank" external
					          class="hover:bg-gray-200 rounded-xl p-1.5">
						<img alt="Link to Minecraft IP Twitter" src="/icons/x.svg"
						     width="20" height="20">
					</NuxtLink>
					<NuxtLink no-prefetch to="https://discord.gg/npsBSDs2hX" target="_blank" external
					          class="hover:bg-gray-200 rounded-xl p-1.5">
						<img alt="Link to Minecraft IP Facebook" src="/icons/discord.svg"
						     width="24" height="24">
					</NuxtLink>
				</div>

				<!-- User button -->
				<div class="flex items-center gap-3 m-lg:justify-center relative m-lg:flex-col"
				     :class="{ 'm-lg:hidden': !hamburgerState }">
					<button v-if="(user && user.username) || usernameCookie"
					        @click="toggleUserDropdown"
					        class="user-button bg-green-800 text-white-0 flex items-center gap-2 h-fit px-3 py-2 rounded-lg
					               m-lg:w-full m-lg:justify-center
					               hover:bg-green-900">
						{{ user ? user.username : usernameCookie }}
						<img alt="Arrow pointing to the right" src="/icons/right_arrow_small.svg"
						     width="12" height="12"
						     :class="{ 'rotate-90': userDropdown }"/>
					</button>

					<button v-else @click="login"
					        class="user-button bg-green-800 text-white-0 flex items-center gap-2 h-fit px-3 py-2 rounded-lg
					               m-lg:w-full m-lg:justify-center
					               hover:bg-green-900">
						Login
						<img alt="Arrow pointing to the right" src="/icons/right_arrow_small.svg"
						     width="12" height="12"/>
					</button>

					<div v-if="usernameCookie"
					     class="flex flex-wrap bg-white-0 gap-2 z-10
					            lg:rounded-b-xl lg:pt-3 lg:shadow-2xl lg:absolute lg:top-full lg:w-[140%] lg:left-[-25%]"
					     :class="{ 'hidden': !userDropdown }">
						<Transition>
							<div v-if="userDropdown"
							     class="flex flex-col place-items-center w-full text-center">
								<NuxtLink no-prefetch to="/server/add"
								          class="hover:bg-gray-200 w-full p-2 m-lg:rounded-xl m-lg:px-3">
									Add Server
								</NuxtLink>
								<NuxtLink no-prefetch to="/server/my"
								          class="hover:bg-gray-200 w-full p-2 m-lg:rounded-xl m-lg:px-3">
									My Servers
								</NuxtLink>
								<NuxtLink no-prefetch to="/server/favourite"
								          class="hover:bg-gray-200 w-full p-2 m-lg:rounded-xl m-lg:px-3">
									Favourite Servers
								</NuxtLink>
								<button @click="userSettings"
								        class="hover:bg-gray-200 w-full p-2 m-lg:rounded-xl m-lg:px-3">
									Edit Profile
								</button>
								<NuxtLink v-if="user && user.is_admin" to="/admin"
								          class="hover:bg-gray-200 w-full p-2 m-lg:rounded-xl m-lg:px-3">
									Admin panel
								</NuxtLink>
								<button @click="logoutUser"
								        class="hover:bg-gray-200 w-full p-2 lg:rounded-b-xl m-lg:rounded-xl">
									Logout
								</button>
							</div>
						</Transition>
					</div>
				</div>
			</div>
		</nav>
	</Transition>
</template>

<script setup>
import { usePopup } from "~/composables/usePopup";
import { logout, setUser } from "~/composables/useUser";
import { notify } from "@kyvg/vue3-notification";

const usernameCookie = useCookie("username");
const loggedInCookie = useCookie("loggedIn");

const navbarInView = ref(true);

onMounted(() => {
	navbarInView.value = window.scrollY < 150;
	document.addEventListener("scroll", () => {
		navbarInView.value = window.scrollY < 150;
	});
});

const hamburgerState = ref(false);
const userDropdown = ref(false);

const toggleHamburger = () => {
	hamburgerState.value = !hamburgerState.value;
};

const toggleUserDropdown = () => {
	userDropdown.value = !userDropdown.value;
};

function closeNavDropdowns() {
	hamburgerState.value = false;
	userDropdown.value = false;
}

const { popup, setPopup } = usePopup();

const login = () => {
	closeNavDropdowns();

	setPopup('LoginPopup');
};

const user = useUser();

const session = useCookie("session");
if (!session.value && loggedInCookie.value) {
	logoutUser();
}

const userSettings = () => {
	setPopup('UserSettingsPopup');
};

function logoutUser() {
	user.value = null;
	logout();
	setUser(null);

	notify({
		type: "success",
		title: "Logged Out",
		text: "You have successfully logged out."
	})
}
</script>

<style lang="scss" scoped>
.user-button {
  img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(0%) hue-rotate(164deg) brightness(102%) contrast(102%);
  }
}
</style>
